<template>
  <v-app id="inspire">
    <systembar></systembar>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" class="header-section">
          <Logo />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <menubar />
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12">
          <Slider v-if="homepage" />
        </v-col>
      </v-row>

      <v-main>
        <v-container>
          <router-view></router-view>
        </v-container>
      </v-main>

      <v-row no-gutters>
        <v-col cols="12" class="footer-section">
          <MyFooter />
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import "./css/fonts.css";

export default {
  name: "App",
  components: {
    Slider: () => import("@/components/Home/Slider.vue"),
    systembar: () => import("@/components/systembar.vue"),
    Logo: () => import("@/components/logo.vue"),
    menubar: () => import("@/components/Home/Menubar.vue"),
    MyFooter: () => import("@/components/footer.vue"),
  },
  data() {
    return {
      homepage: false,
    };
  },
  watch: {
    $route(to) {
      this.checkHomepage(to);
    },
  },
  mounted() {
    this.checkHomepage(this.$route);
  },
  methods: {
    checkHomepage(route) {
      this.homepage = route.path === "/";
      console.log(
        this.homepage
          ? "You are on the home page."
          : "You are on a different page."
      );
    },
  },
};
</script>

<style>
#inspire {
  background-color: #f5f5f5;
  font-family: "Roboto", sans-serif;
}

.v-system-bar {
  background-color: #2196f3;
  color: white;
  font-size: 1.2rem;
}

.header-section {
  background-color: #fff;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.menubar {
  background-color: #f5f5f5;
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.footer-section {
  background-color: #2196f3;
  color: white;
  padding: 32px;
  text-align: center;
  font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
  .footer-section {
    padding: 10%;
    font-size: 1rem;
  }
}
</style>
