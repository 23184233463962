<template>
  <v-div>
    <p class="font-2 weight-600 text-center pt-3">About Us</p>
    <br />

    <v-row>
      <v-col cols="12" sm="6" md="8">
        <v-lazy
          v-model="isActive"
          :options="{
            threshold: 0.5,
          }"
          transition="fade-transition"
        >
          <v-card class="elevation-1 rounded-lg pa-3" hover min-height="545">
            <v-card-title class="h5">
              <v-strong>Rupali Investment Limited</v-strong>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              Rupali Investment Limited (RIL) is a wholly-owned subsidiary of
              Rupali Bank Limited. RIL obtained a license from the Bangladesh
              Securities and Exchange Commission (BSEC) to act as a full-fledged
              Merchant Bank to carry out all the Merchant Banking operations
              under the BSEC (Merchant Banker and Portfolio Manager)
              regulations, 1996 and started its journey on 09 August 2010. The
              Company has a separate Board of Directors consisting of 9 (Nine)
              members. Other than Chief Executive Officer (CEO).
              <v-divider></v-divider>
              <p class="font-weight-black text-h6">Objectives:</p>

              <p>
                <v-icon color="green">mdi-checkbox-marked</v-icon>

                <strong>
                  To carry out merchant banking activities including issue
                  management, underwriting, and portfolio management with a view
                  to developing a strong capital market;</strong
                >
              </p>
              <p>
                <v-icon color="green">mdi-checkbox-marked</v-icon>

                <strong
                  >To widen the scope and area of investment by mobilizing
                  savings of small and medium level investors thereby generating
                  demand for securities;</strong
                >
              </p>
              <p>
                <v-icon color="green">mdi-checkbox-marked</v-icon>

                <strong>
                  To achieve high quality in issue management and thereby
                  facilitate the capital market with an adequate supply of
                  securities;
                </strong>
              </p>
              <p>
                <v-icon color="green">mdi-checkbox-marked</v-icon>

                <strong
                  >To establish a perfect combination of high-quality
                  purchase-sell execution capabilities and create a large
                  network among investors and the broker community.
                </strong>
              </p>
            </v-card-text>
          </v-card>
        </v-lazy>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-card class="elevation-1 pa-3" hover min-height="530">
          <v-card-title>
            RIL has the following unique advantages to carry out its merchant
            banking operation:</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <p class="font-weight-black"></p>
            <p>
              <v-icon color="green">mdi-checkbox-marked</v-icon>

              <strong>Capital base.</strong>
            </p>
            <p>
              <v-icon color="green">mdi-checkbox-marked</v-icon>

              <strong>Excellent working environment.</strong>
            </p>
            <p>
              <v-icon color="green">mdi-checkbox-marked</v-icon>

              <strong>Adequate experts and professionals.</strong>
            </p>
            <p>
              <v-icon color="green">mdi-checkbox-marked</v-icon>

              <strong>A large number of skilled manpower.</strong>
            </p>
            <p>
              <v-icon color="green">mdi-checkbox-marked</v-icon>

              <strong
                >Adequate infrastructure and logistic support with up-to-date
                computerized facilities.</strong
              >
            </p>

            <v-divider></v-divider>
            <p></p>
            <div class="pl-5">
              <p class="text-h5 text-center">Current Date and Time</p>
              <p class="text-center">{{ currentDateTime }}</p>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-div>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
    currentDateTime: "",
  }),
  mounted() {
    this.updateDateTime();
    // Update the current date and time every second
    this.updateInterval = setInterval(this.updateDateTime, 1000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  },
  methods: {
    updateDateTime() {
      const now = new Date();
      this.currentDateTime = now.toLocaleString();
    },
  },
};
</script>

<style></style>
