// src/firebase.js
// v9 compat packages are API compatible with v8 code
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import "firebase/compat/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCptxnVKm4j33IGx6WLsSR4rr34JdjRAE8",
  authDomain: "rupali-investment.firebaseapp.com",
  projectId: "rupali-investment",
  storageBucket: "rupali-investment.appspot.com",
  messagingSenderId: "1032214538013",
  appId: "1:1032214538013:web:cd03e9840f2c6e3f68a3f4",
  measurementId: "G-663NW2Q2C4"
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

const getUserRole = async (userId) => {
  const userDoc = await db.collection("users").doc(userId).get();
  return userDoc.exists ? userDoc.data().role : null;
};

export { auth, db, getUserRole, storage };
