import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import Login from "@/views/Login.vue";
import Dashboard from "@/views/Dashboard.vue";
import Admin from "@/views/Admin.vue";
import AboutUSedit from "@/views/AboutUSedit.vue";
// import firebase from "../firebase";
import firebase from "firebase/compat/app";

// import { auth, getUserRole } from "@/firebase";
// import { auth} from "@/firebase";
Vue.use(VueRouter)

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,

    children: [
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/slideAdd",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/slideAdd.vue"),

        meta: { requiresAuth: true },
      },
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/ProfileUpdate",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/ProfileUpdate.vue"),

        meta: { requiresAuth: true },
      },
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/CareerNoticeAdd",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/CareerNoticeAdd.vue"
          ),

        meta: { requiresAuth: true },
      },
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/IpoLotteryresultAdd",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/IpoLotteryresultAdd.vue"
          ),

        meta: { requiresAuth: true },
      },
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/BoardOfDirectorsAdd",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/BoardOfDirectorsAdd.vue"
          ),

        meta: { requiresAuth: true },
      },
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/AuditCommitteAdd",
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/AuditCommitteAdd.vue"
          ),

        meta: { requiresAuth: true },
      },
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/GalleryaAdd",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/GalleryaAdd.vue"),

        meta: { requiresAuth: true },
      },
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/aboutusedit",
        component: AboutUSedit,
        meta: { requiresAuth: true },
      },
      {
        // A will be rendered in the second <router-view>
        // when /your-sidebar-url/a is matched
        path: "/NoticeAdd",

        component: () =>
          import(/* webpackChunkName: "about" */ "../views/NoticeAdd.vue"),
        meta: { requiresAuth: true },
      },
      // {
      //   // B will be rendered in the second <router-view>
      //   // when /your-sidebar-url/b is matched
      //   // path: "b",
      //   // component: B,
      // },
    ],
    meta: { requiresAuth: true },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin",
    component: Admin,
    meta: { requiresAuth: true },
  },
  { path: "/login", component: Login },

  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/notice",
    name: "Notice",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/Notice.vue"),
  },
  {
    path: "/BasicFeatures",
    name: "BasicFeatues",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/BasicFeatures.vue"),
  },
  {
    path: "/AuditCommittee",
    name: "AuditCommittee",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/AuditCommitte.vue"),
  },
  {
    path: "/BusinessPrinciples",
    name: "BusinessPrinciples",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/BusinessPrinciples.vue"
      ),
  },
  {
    path: "/CorporateProfile",
    name: "CorporateProfile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/CorporateProfile.vue"
      ),
  },
  {
    path: "/Functions",
    name: "Functions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/Functions.vue"),
  },
  {
    path: "/KeyInformation",
    name: "KeyInformation",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/KeyInformation.vue"),
  },
  {
    path: "/BoardOfDirectors",
    name: "BoardOfDirectors",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/BoardOfDirectors.vue"
      ),
  },
  {
    path: "/IpoNotice",
    name: "IpoNotice",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/IpoNotice.vue"),
  },
  {
    path: "/career",
    name: "career",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/career.vue"),
  },

  {
    path: "/gallery",
    name: "gallery",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/Gallary.vue"),
  },
  {
    path: "/Overview",
    name: "Overview",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/Overview.vue"),
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/Contactus.vue"),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach(async (to, from, next) => {
//   const currentUser = auth.currentUser;
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (!currentUser) {
//       next("/login");
//     } else {
//       try {
//         await currentUser.getIdToken();
//         next("/dashboard");
//       } catch (error) {
//         await auth.signOut();
//         next("/login");
//       }
//     }
//   } 
// });
router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // Check if user is authenticated
  const isAuthenticated = await new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      resolve(user);
    });
  });

  if (requiresAuth && !isAuthenticated) {
    next("/login");
  } else if (!requiresAuth && isAuthenticated) {
     next();
    
  } else {
    next();
  }
});
export default router
