<template>
  <v-container class="fill-height login-container" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-card-title class="headline">Login</v-card-title>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                v-model="email"
                label="Email"
                prepend-icon="mdi-email"
                required
                aria-label="Email"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                type="password"
                prepend-icon="mdi-lock"
                required
                aria-label="Password"
              ></v-text-field>
              <v-btn type="submit" color="primary" block class="mt-4"
                >Login</v-btn
              >
            </v-form>
            <v-btn text @click="resetPassword" color="secondary" class="mt-2">
              Forgot Password?
            </v-btn>
            <v-alert
              v-if="errorMessage"
              type="error"
              dismissible
              @input="errorMessage = ''"
              class="mt-4"
            >
              {{ errorMessage }}
            </v-alert>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { auth } from "../firebase";

export default {
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    async login() {
      try {
        await auth.signInWithEmailAndPassword(this.email, this.password);
        this.$router.push("/dashboard");
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    async resetPassword() {
      if (!this.email) {
        this.errorMessage =
          "Please enter your email address to reset password.";
        return;
      }
      try {
        await auth.sendPasswordResetEmail(this.email);
        this.errorMessage =
          "Password reset email sent. Please check your inbox.";
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
  },
};
</script>
<style scoped>
.fill-height {
  height: 100vh;
}
.login-container {
  background: linear-gradient(to right, #6a11cb, #2575fc);
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-card {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px;
  border-radius: 10px;
}
.v-card-title {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}
.v-btn {
  font-size: 16px;
}
.v-btn--text {
  color: #6a11cb;
}
</style>
