<template>
  <div>
    <div class="bg-1 pa-3">
      <!-- <specialsHomeVue /> -->
      <vissioncard />
      <aboutus />
      <!-- <contactHomeVue /> -->
      <!-- <recommendHomeVue /> -->
      <!-- <testimonialHomeVue /> -->
      <!-- <discountHomeVue /> -->
    </div>
    <GoogleMaps />
  </div>
</template>

<script>
// import contactHomeVue from "../components/Home/contact.home.vue";
// import discountHomeVue from "../components/Home/discount.home.vue";
import aboutus from "@/components/Home/Aboutus.vue";
// import recommendHomeVue from "../components/Home/recommend.home.vue";
// import specialsHomeVue from "../components/Home/specials.home.vue";
// import testimonialHomeVue from "../components/Home/testimonial.home.vue";
import vissioncard from "@/components/vissioncard.vue";
import GoogleMaps from "@/components/Home/googleMap.vue";

export default {
  name: "Home",

  components: {
    GoogleMaps,
    // specialsHomeVue,
    aboutus,
    // contactHomeVue,
    // recommendHomeVue,
    // testimonialHomeVue,
    // discountHomeVue,
    vissioncard,
  },
};
</script>
<style></style>
