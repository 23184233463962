<template>
  <div>
    <v-row class="d-flex py-2" justify="justify-space-between">
      <v-col cols="12" xl="6" lg="6" md="6">
        <v-card
          class="mx-auto pa-3"
          rounded
          color="green"
          dark
          hover
          min-height="250"
        >
          <v-card-title class="h5 font-weight-light">
            <v-icon large left> mdi-eye-outline </v-icon>
            <span>Vision</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-subtitle class="text-justify font-title white--text">
            To become the leading investment bank through achieving maximum
            economic value from the optimum resource utilization. To serve the
            people who have limited capital but large ambition to build their
            wealth and enable them to move high up by providing maximum economic
            value from the optimum resource utilization.
          </v-card-subtitle>

          <!-- <v-card-actions>
              <v-list-item class="grow">
                <v-list-item-avatar color="grey darken-3">
                  <v-img
                    class="elevation-6"
                    alt=""
                    src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>Evan You</v-list-item-title>
                </v-list-item-content>

                <v-row align="center" justify="end">
                  <v-icon class="mr-1"> mdi-heart </v-icon>
                  <span class="subheading mr-2">256</span>
                  <span class="mr-1">·</span>
                  <v-icon class="mr-1"> mdi-share-variant </v-icon>
                  <span class="subheading">45</span>
                </v-row>
              </v-list-item>
            </v-card-actions> -->
        </v-card>
      </v-col>

      <v-col cols="12" xl="6" lg="6" md="6">
        <v-lazy
          v-model="isActive"
          :options="{
            threshold: 0.5,
          }"
          transition="fade-transition"
        >
          <v-card
            class="mx-auto pa-3"
            color="red lighten-1"
            dark
            hover
            min-height="250"
          >
            <v-card-title>
              <v-icon large left> mdi-motion-sensor </v-icon>
              <span class="font-weight-light">Mission</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-subtitle class="text-justify font-title white--text">
              Develop sustainable partnership through delivering innovative
              solution to our clients to meet their immediate and long term
              needs. Achieve the maximum economic value from the optimum
              resources utilization. Create value through uncovering opportunity
              and success for our stakeholders. Expand market horizon to achieve
              a sustainable economic growth.
            </v-card-subtitle>
          </v-card>
        </v-lazy>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data: () => ({
    isActive: false,
  }),
};
</script>

<style></style>
