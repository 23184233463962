<!-- src/views/Admin.vue -->
<template>
  <v-container>
    <h1>Admin Panel</h1>
    <v-btn @click="logout" color="primary">Logout</v-btn>
  </v-container>
</template>

<script>
import { auth } from "../firebase";

export default {
  methods: {
    async logout() {
      await auth.signOut();
      this.$router.push("/login");
    },
  },
};
</script>
