import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


//boostrap Vue start

// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// // Import Bootstrap and BootstrapVue CSS files (order is important)
// // import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// // Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue);
// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin);
// //End boostrap vue





// The following components are automatically installed globally
// If you would like to define your own component names,
// see below
// GmapMap
// GmapMarker
// GmapCluster
// GmapInfoWindow
// GmapPolyline
// GmapPolygon
// GmapCircle
// GmapRectangle
// GmapPlaceInput

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
