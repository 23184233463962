<template>
  <v-app>
    <v-navigation-drawer
      app
      v-model="drawer"
      dark
      color="primary"
      width="220"
      elevation="2"
    >
      <v-list dense>
        <v-list-item
          v-for="item in navItems"
          :key="item.title"
          :to="item.route"
          link
          @click="handleNavigation(item)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark elevation="2">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>Admin Dashboard</v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { auth } from "../firebase";

export default {
  data() {
    return {
      drawer: true,
      navItems: [
        {
          title: "Dashboard",
          icon: "mdi-view-dashboard-outline",
          route: "/dashboard",
        },
        {
          title: "Profile Update",
          icon: "mdi-account-cog-outline",
          route: "/ProfileUpdate",
        },
        {
          title: "Slide Image Add",
          icon: "mdi-image-plus",
          route: "/slideAdd",
        },
        {
          title: "Gallery Image Add",
          icon: "mdi-folder-multiple-image",
          route: "/GalleryaAdd",
        },
        {
          title: "Notice Add",
          icon: "mdi-bell-plus-outline",
          route: "/NoticeAdd",
        },
        {
          title: "Career Notice Add",
          icon: "mdi-briefcase-plus-outline",
          route: "/CareerNoticeAdd",
        },
        {
          title: "IPO Lottery Result Add",
          icon: "mdi-finance",
          route: "/IpoLotteryresultAdd",
        },
        {
          title: "Board Of Directors Add",
          icon: "mdi-account-group-outline",
          route: "/BoardOfDirectorsAdd",
        },
        {
          title: "Audit Committee Add",
          icon: "mdi-clipboard-check-outline",
          route: "/AuditCommitteAdd",
        },
        {
          title: "Users",
          icon: "mdi-account-multiple-outline",
          route: "/users",
        },
        { title: "Logout", icon: "mdi-logout", action: "logout" },
      ],
    };
  },
  methods: {
    async logout() {
      await auth.signOut();
      this.$router.push("/login");
    },
    handleNavigation(item) {
      if (item.route) {
        this.$router.push(item.route);
      } else if (item.action) {
        this[item.action]();
      }
    },
  },
};
</script>
<style scoped>
.v-navigation-drawer {
  background-color: #1e1e1e;
}

.v-list-item {
  transition: background-color 0.3s;
}

.v-list-item:hover {
  background-color: #333333;
}

.v-icon {
  color: white;
}

.v-list-item-title {
  color: white;
}

.v-app-bar {
  background-color: #1976d2 !important;
}

.v-toolbar-title {
  color: white;
}

.v-container {
  padding: 20px;
}

@media (max-width: 600px) {
  .v-navigation-drawer {
    width: 200px !important;
  }

  .v-app-bar {
    height: 56px !important;
  }

  .v-toolbar-title {
    font-size: 18px;
  }
}
</style>
